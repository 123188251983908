/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/styles/style.scss"

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (typeof window.IntersectionObserver === `undefined`) {
    import(`intersection-observer`);
  }
};
// export const onRouteUpdate = (location) => {
//   console.log(location.location.hash);
//   console.log(document.querySelector('#full'));
//   if (location.location.hash && document.querySelector(`${location.location.hash}`) !== null) {
//     setTimeout(() => {
//       document.querySelector(`${location.location.hash}`).scrollIntoView();
//     }, 0);
//   }
// };
